module controllers {
    export module sales {
        interface ISalesSkuUpdateScope extends ng.IScope {
            salesOrderUpdateCtrl: controllers.sales.SalesOrderUpdateCtrl
        }

        interface ISalesUpdateParams extends ng.ui.IStateParamsService {
            sosId: number;
            soId: number;
        }

        export class salesOrderSkuUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$stateParams', "salesOrderSKUService", "productColourService", "productSizeService", "packConfigurationService", "$state",
                "classificationValueService", "unitOfMeasureService", "vatRateService", "$anchorScroll", "productService", "skuService", "bsLoadingOverlayService",
                "countryService", "lookupService", "$timeout", "warehouseService","salesOrderItemTrackingService","menuService"];

            public isDisabled: boolean = false;

            public poSku: interfaces.sales.ISalesOrderSKU;
            breadCrumbDesc: string;
            poNumber: string;
            selectedTab: number = 0;

            soId: number;

            selectedInternationalTrade: interfaces.sales.ISalesOrderNationalMeasureCode;

            apiUoM: uiGrid.IGridApi;

            apiSIT: uiGrid.IGridApi;
           
            apiTrade: uiGrid.IGridApi;

            skuLookup: interfaces.applicationcore.ILookupDirective;

            tariffTypes: interfaces.applicationcore.IDropdownModel[];

            allowTariffSalesOrder: boolean;

            constructor(private $scope: ISalesSkuUpdateScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private $stateParams: ISalesUpdateParams, private salesOrderSKUService: interfaces.sales.ISalesOrderSKUService,
                public productColourService: interfaces.master.IProductColourService, public productSizeService: interfaces.master.IProductSizeService,
                public packConfigurationService: interfaces.master.IPackConfigurationService, private $state: ng.ui.IStateService,
                public classificationValueService: interfaces.applicationcore.IClassificationValueService, public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                public vatRateService: interfaces.master.IVatRateService, public $anchorScroll: ng.IAnchorScrollService,
                public productService: interfaces.master.IProductService, public skuService: interfaces.master.ISKUService,
                public bsLoadingOverlayService, public countryService: interfaces.applicationcore.ICountryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                public $timeout: ng.ITimeoutService, public warehouseService: interfaces.master.IWarehouseService,public salesOrderItemTrackingService: services.sales.salesOrderItemTrackingService,
                private menuService: interfaces.applicationcore.IMenuService) {

                var loadPromises: ng.IPromise<any>[] = [];

                this.soId = $stateParams.soId;

                loadPromises.push(this.loadSku($stateParams.sosId, $stateParams.soId));
                loadPromises.push(this.loadTariffTypes());
                loadPromises.push(this.getAllowTariffSalesOrderRight());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.skuupdate'
                },
                    () => {
                        return $q.all(loadPromises);
                    });

            }

            getAllowTariffSalesOrderRight() {
                this.allowTariffSalesOrder = false;                
                return this.menuService.getGTSConnectMenuItem(656).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.allowTariffSalesOrder = true;
                    }
                }).$promise;
            }


            public loadSku(sosId: number, salId: number) {
                return this.salesOrderSKUService.getDetail().query({ sosId: sosId, salId: salId }, (data: interfaces.sales.ISalesOrderSKU) => {
                    this.poSku = data;
                    this.breadCrumbDesc = data.SalesOrderDetail.SequenceNumber + " - " + (data.SKU ? data.SKU.Code + " - " + data.SKU.Description : "");

                    this.isDisabled = this.poSku.SalesOrderDetail.IsIntegrated;

                    this.gvwSOSUoM.data = this.poSku.UoMList;
                    this.gvwSOSIT.data = this.poSku.ItemTrackingList;

                    this.gvwSOSTrade.data = this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList;
                  

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSKUs(searchText: string) {
                if (this.poSku) {
                    return this.skuService.getDropdownList(this.poSku.SalesOrderDetail.OwnerEntityId, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            public loadWarehouse() {
                var defer = this.$q.defer();

                if (this.poSku) {
                    this.warehouseService.getDropdownList().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId, unLocationCodeId: this.poSku.SalesOrderDetail.LoadingLocationId }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            loadVatRate(searchText: string) {
                if (this.poSku) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId, countryId: 249}, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            vatRateChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'so.skuupdate'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (vatRate: interfaces.master.IVatRate) => {
                                this.poSku.SalesOrderDetail.VatRateValue = vatRate.CurrentRate;
                                this.calculateVatValue();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                } else {
                    this.poSku.SalesOrderDetail.VatRateValue = 0;
                    this.calculateVatValue();
                }
            }

            recalculateValues() {
                this.poSku.PriceQty = this.poSku.PricingUOMConversion * this.poSku.OrderedQty;
                this.poSku.SellingValue = Math.round((this.poSku.SellingPrice * this.poSku.PriceQty) * 100) / 100;
                this.poSku.BaseCurrencySellingValue = this.poSku.BaseCurrencySellingPrice * this.poSku.OrderedQty;

                this.calculateVatValue();
            }

            calculateVatValue() {
                this.poSku.VATValue = 0;
                if (!this.poSku.SalesOrderDetail.IsZeroRated)
                    this.poSku.VATValue = Math.round((this.poSku.SellingValue * (this.poSku.SalesOrderDetail.VatRateValue / 100)) * 100) / 100;
            }

            loadProducts(searchText: string) {
                var defer = this.$q.defer();
                defer.resolve([]);
                return defer.promise;
            }

            loadTariffTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.TariffCodeType
                }, (resultList) => {
                    this.tariffTypes = resultList;
                }).$promise;
            }

            loadProductColours() {
                var defered = this.$q.defer();

                if (this.poSku && this.poSku.SalesOrderDetail) {
                    this.productColourService.getDropdownList().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadProductSizes() {
                var defered = this.$q.defer();

                if (this.poSku && this.poSku.SalesOrderDetail) {
                    this.productSizeService.getDropdownList().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadPackConfigurations(searchText: string) {
                var defered = this.$q.defer();

                if (this.poSku && this.poSku.SalesOrderDetail) {
                    this.packConfigurationService.getDropdownList().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId, searchText:searchText }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadUoMList() {
                if (this.poSku && this.poSku.SalesOrderDetail) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            public loadVatRates() {
                if (this.poSku && this.poSku.SalesOrderDetail) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.poSku.SalesOrderDetail.OwnerEntityId }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            

          

            insertUoM() {
                this.poSku.UoMList.push(<interfaces.sales.ISalesOrderSKUUoM>{
                    SalesOrderSKUId: this.poSku.Id,
                    IsActive: true
                });
            }

            skuChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'so.skuupdate'
                    },
                        () => {
                            return this.loadSkuDefaults(model.Id);
                        });
                }

            };

            loadSkuDefaults(Id: number) {

                return this.salesOrderSKUService.getSKUDefaults().query({ skuId: Id, sosId: this.soId }, (result: interfaces.master.ISKU) => {
                    this.breadCrumbDesc = this.poSku.SalesOrderDetail.SequenceNumber + " - " + result.Code;

                    this.poSku.SalesOrderDetail.Product = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.ProductId,
                        Code: result.Product.Code,
                        Description: result.Product.ShortDescription,
                        Display: result.Product.Code + " - " + result.Product.ShortDescription,
                        Selected: true
                    };

                    this.poSku.SalesOrderDetail.ProductShortLocaleDescription = result.Product.ShortDescription;
                    this.poSku.SalesOrderDetail.ProductLongLocaleDescription = result.Product.ShortDescription;

                    this.poSku.SalesOrderDetail.OrderUoM = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Product.OrderUnitOfMeasureId,
                        Code: result.Product.UnitOfMeasureCode,
                        Description: result.Product.UnitOfMeasureDescription,
                        Display: result.Product.UnitOfMeasureCode
                    }

                    this.poSku.SalesOrderDetail.PricingUoM = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Product.PricingUnitOfMeasureId,
                        Code: result.Product.UnitOfPricingCode,
                        Description: result.Product.UnitOfPricingDescription,
                        Display: result.Product.UnitOfPricingCode
                    }

                    if (result.DefaultVatRate) {
                        this.poSku.SalesOrderDetail.VatRateRate = result.DefaultVatRate;
                        this.poSku.SalesOrderDetail.VatRateValue = result.DefaultVatRateValue;
                    }

                    this.poSku.PackageConfiguration = result.PackConfiguration;
                    this.poSku.Colour = result.ProductColour;
                    this.poSku.Size = result.ProductSize;

                    this.poSku.SalesOrderDetail.ProductCategoryHierarchicalId = result.Product.ProductCategoryId;
                    this.poSku.SalesOrderDetail.ProductCategoryHierarchicalCode = result.Product.CategoryCode;
                    this.poSku.SalesOrderDetail.ProductCategoryDescription = result.Product.CategoryDescription;

                    this.poSku.UoMList = _.map(result.ProductUnitOfMeasures, (o: interfaces.master.IProductUnitOfMeasure) => {
                        return <interfaces.sales.ISalesOrderSKUUoM>{
                            SalesOrderSKUId: this.poSku.Id,
                            UoM: <interfaces.applicationcore.IDropdownModel>{
                                Id: o.UnitOfMeasureId,
                                Code: o.UOMCode,
                                Description: o.UOMDescription,
                                Display: o.UOMCode + ' - ' + o.UOMDescription
                            },
                            Quantity: o.UnitOfMeasureQuantity,
                            IsActive: true
                        }
                    });

                    this.gvwSOSUoM.data = this.poSku.UoMList;

                   
                    this.poSku.OrderedQty = 0;
                    this.poSku.PriceQty = 0;
                    this.poSku.SellingPrice = 0;
                    this.poSku.SellingValue = 0;
                    this.poSku.BaseCurrencySellingPrice = 0;
                    this.poSku.BaseCurrencySellingValue = 0;
                    this.poSku.VATValue = 0;
                }).$promise;
            }

            addInternationalTrade_click() {

                var code = '';

                var zaTariffCode = this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList.find(x => x.NationalMeasureCountryId === 249);

               if (zaTariffCode) {
                   code = zaTariffCode.NationalMeasureCode.substring(0, 6);
               } else {
               // No matching element was found
               }

                this.lookupService.openTariffLookup(undefined, Enum.EnumTariffCodeType.NationMeasure, true,code, this.poSku.SalesOrderDetail.OwnerEntityId).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {
                        this.addInternationalTrade(result);
                    }, (errorResult) => {

                    });
            }

            editSalesOrderNationalMeasureCode(internationalTrade: interfaces.sales.ISalesOrderNationalMeasureCode) {

                var code = internationalTrade.NationalMeasureCode;
                if ( code === ''){
                    var zaTariffCode = this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList.find(x => x.NationalMeasureCountryId === 249);

                    if (zaTariffCode) {
                        code = zaTariffCode.NationalMeasureCode.substring(0, 6);
                    } else {
                    // No matching element was found
                    }
                }


                this.lookupService.openTariffLookup(internationalTrade.NationalMeasureCountryId, Enum.EnumTariffCodeType.NationMeasure, true, code, this.poSku.SalesOrderDetail.OwnerEntityId,internationalTrade.CodeTypeClassificationValueId).result.then(
                    (lookupResult: interfaces.tariff.INationalMeasureCodeLookup) => {

                        this.bsLoadingOverlayService.wrap({
                        }, () => {
                            return this.countryService.get().get({ cntId: lookupResult.CountryId }, (countryResult) => {

                                internationalTrade.CodeTypeClassificationValueId = lookupResult.CodeTypeClassificationValueId;
                                internationalTrade.CodeTypeClassificationValueDescription = _.find(this.tariffTypes, (o) => { return o.Id === lookupResult.CodeTypeClassificationValueId }).Description;
                                internationalTrade.ImportDuty = lookupResult.DutyTypeCode;
                                internationalTrade.NationalMeasureCountryId = lookupResult.CountryId;
                                internationalTrade.NationalMeasureCode = lookupResult.NationalMeasureCode;
                                internationalTrade.NationalMeasureCountryCode = countryResult.Code;


                                if (this.apiTrade) {
                                    this.$timeout(() => {
                                        this.apiTrade.rowEdit.setRowsDirty([internationalTrade]);
                                    });
                                }

                            }).$promise;
                        });


                    }, (errorResult) => {

                    });
            }

            addInternationalTrade(nationalMeasureCodeLookup: interfaces.tariff.INationalMeasureCodeLookup) {
                this.bsLoadingOverlayService.wrap({
                }, () => {
                    return this.countryService.get().get({ cntId: nationalMeasureCodeLookup.CountryId }, (result) => {

                        var poNationalMeasure = {
                            CodeTypeClassificationValueId: nationalMeasureCodeLookup.CodeTypeClassificationValueId,
                            CodeTypeClassificationValueDescription: _.find(this.tariffTypes, (o) => { return o.Id === nationalMeasureCodeLookup.CodeTypeClassificationValueId }).Description,
                            ImportDuty: nationalMeasureCodeLookup.DutyTypeCode,
                            NationalMeasureCountryId: nationalMeasureCodeLookup.CountryId,
                            NationalMeasureCode: nationalMeasureCodeLookup.NationalMeasureCode,
                            NationalMeasureCountryCode: result.Code,
                            StartDate: moment.utc(),
                            EndDate: undefined,
                            IsActive: true
                        };

                        this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList.push(<interfaces.sales.ISalesOrderNationalMeasureCode>poNationalMeasure);

                        if (this.apiTrade) {
                            this.$timeout(() => {
                                this.apiTrade.rowEdit.setRowsDirty([poNationalMeasure]);
                            });
                        }
                    }).$promise;
                });
            }

            removeInternationalTrade_click() {
                if (this.apiTrade.selection.getSelectedRows().length > 0) {
                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the record?").then((result) => {

                        var selectedInternationalTrade = this.apiTrade.selection.getSelectedRows()[0];
                        if (result && !selectedInternationalTrade.Id) {
                            _.remove(this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList, selectedInternationalTrade);
                        } else {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'so.skuupdate'
                            },
                                () => {
                                    return this.salesOrderSKUService.deleteNationalMeasureCode(selectedInternationalTrade.Id).delete().$promise.then((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage) {
                                            _.remove(this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList, selectedInternationalTrade);
                                        }
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    });
                                });
                        }
                    });

                }
            }

            internationalTradeSelected(internationalTrade: interfaces.sales.ISalesOrderNationalMeasureCode) {
               if (this.selectedInternationalTrade !== internationalTrade) {
                    this.selectedInternationalTrade = internationalTrade;
                }


                
            }


            gvwSOSUoM: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerUoMApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.salesOrderUpdateCtrl.SalesOrder ? this.$scope.salesOrderUpdateCtrl.SalesOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: 'UoM',
                    displayName: 'UoM',
                    field: 'UoM',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-disabled="MODEL_COL_FIELD && MODEL_COL_FIELD.Id" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.salesOrderSkuUpdateCtrl.loadUoMList()"></gts-dropdown>
                                </form>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'Qty',
                    field: 'Quantity',
                    displayName: 'Qty',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00001" min="0" />
                                    </p>
                                </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                },
                {
                    name: 'IsActive',
                    field: 'IsActive',
                    displayName: 'Active',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.salesOrderSkuUpdateCtrl.changeUoM(row.entity)">
                                </p>
                            </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                }]
            };

          
            gvwSOSTrade: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerTradeApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.salesOrderUpdateCtrl.SalesOrder ? this.$scope.salesOrderUpdateCtrl.SalesOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    enableCellEdit: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-disabled="!grid.appScope.salesOrderSkuUpdateCtrl.allowTariffSalesOrder" class="btn btn-default btn-sm" ng-click="grid.appScope.salesOrderSkuUpdateCtrl.editSalesOrderNationalMeasureCode(row.entity)">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: 'CODETYPE',
                    displayName: 'Code Type',
                    field: 'CodeTypeClassificationValueDescription',
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 150
                }, {
                    name: 'COUNTRY',
                    displayName: 'Country',
                    field: 'NationalMeasureCountryCode',
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 75
                }, {
                    name: 'CODE',
                    displayName: 'Code',
                    field: 'NationalMeasureCode',
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 150
                }, {
                    name: 'STARTDATE',
                    displayName: 'Start Date',
                    field: 'StartDate',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-startDate" ng-required="true"></gts-date>
                                </form>`,
                    enableSorting: false,
                    width: 100
                }, {
                    name: 'ENDDATE',
                    displayName: 'End Date',
                    field: 'EndDate',
                    enableCellEditOnFocus: true,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>`,
                    enableSorting: false,
                    width: 100
                }, {
                    name: 'PreviousProcedureLineNo',
                    field: 'PreviousProcedureLineNo',
                    displayName: 'Previous Procedure Line',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-PreviousProcedureLineNo" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    enableSorting: false,
                    width: 200
                }, {
                    name: 'PreviousProcedureMRN',
                    field: 'PreviousProcedureMRN',
                    displayName: 'Previous Procedure MRN',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-PreviousProcedureMRN" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'IMPDUTY',
                    displayName: 'Import Duty',
                    field: 'ImportDuty',
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 150
                }]
            };

            registerUoMApi(gridApi: uiGrid.IGridApi) {
                this.apiUoM = gridApi;
            }

            changeUoM(salesOrderSKUUom: interfaces.sales.ISalesOrderSKUUoM) {
                this.apiUoM.rowEdit.setRowsDirty([salesOrderSKUUom]);
            }

            
            registerTradeApi(gridApi: uiGrid.IGridApi) {
                this.apiTrade = gridApi;
            }

            SaveSalesSKU(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiUoM) {
                    this.poSku.UoMList = _.map(this.apiUoM.rowEdit.getDirtyRows(this.apiUoM.grid), (o) => { return o.entity });
                } else {
                    this.poSku.UoMList = [];
                }

                
                if (this.apiTrade) {
                    this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList = _.map(this.apiTrade.rowEdit.getDirtyRows(this.apiTrade.grid), (o) => { return o.entity });
                } else {
                    this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList = [];
                }

                this.salesOrderSKUService.save().save(this.poSku, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    if (!this.poSku.Id) {
                        this.poSku.Id = Number(data.ID);
                    }

                    if (this.apiUoM) {
                        this.apiUoM.rowEdit.setRowsClean(this.poSku.UoMList);
                    }

                   
                    if (this.apiTrade) {
                        this.apiTrade.rowEdit.setRowsClean(this.poSku.SalesOrderDetail.SalesOrderNationalMeasureCodeList);
                    }

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.skuupdate'
                },
                    () => {
                        return this.SaveSalesSKU().then((data: boolean) => {
                            if (data) {
                                this.loadSku(this.$stateParams.sosId, this.$stateParams.soId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.SalesOrders.Update.Sku", { sosId: this.poSku.Id });
                            }
                        });
                    });

            }

            SaveClose() {


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.skuupdate'
                },
                    () => {
                        return this.SaveSalesSKU().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }


            insertIT() {
                this.poSku.ItemTrackingList.push(<interfaces.sales.ISalesOrderItemTracking>{
                    Id: 0,
                    SalesOrderSKUId: this.poSku.Id,
                    IsActive: true
                });
            }

            removeIT() {

                if (this.apiSIT.selection.getSelectedRows().length > 0) {

                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the Item Tracking?").then((result) => {
                        var selectedRow: interfaces.sales.ISalesOrderItemTracking = this.apiSIT.selection.getSelectedRows()[0];
                        if (result && selectedRow.Id <= 0) {
                            _.remove(this.poSku.ItemTrackingList, selectedRow);
                        } else if (result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'so.skuupdate'
                            },
                                () => {
                                    return this.salesOrderItemTrackingService.remove(selectedRow.Id).remove((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage)
                                            this.loadSku(this.$stateParams.sosId, this.$stateParams.soId);
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }
                    });

                }
            }

            registerSITApi(gridApi: uiGrid.IGridApi) {
                this.apiSIT = gridApi;
            }

            gvwSOSIT: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerSITApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.salesOrderUpdateCtrl.SalesOrder ? this.$scope.salesOrderUpdateCtrl.SalesOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: 'Qty',
                    field: 'Quantity',
                    displayName: 'Qty',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="Qty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                    </p>
                                </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 150
                }, {
                    name: 'LotNumber',
                    field: 'LotNumber',
                    displayName: 'Lot Number',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="LotNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor/>
                                    </p>
                                </form>`,
                    cellClass: 'text-left',
                    enableSorting: false,
                    width: 150
                    }
                    , {
                        name: 'SerialNumber',
                        field: 'SerialNumber',
                        displayName: 'Serial Number',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="SerialNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor/>
                                    </p>
                                </form>`,
                        cellClass: 'text-left',
                        enableSorting: false,
                        width: 150
                    }
                    , {
                        name: 'TankNumber',
                        field: 'TankNumber',
                        displayName: 'Tank Number',
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" ignore-wheel name="TankNumber" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor/>
                                    </p>
                                </form>`,
                        cellClass: 'text-left',
                        enableSorting: false,
                        width: 150
                    },
                     {
                         name: 'ProductionDate',
                        displayName: 'Production Date',
                         field: 'ProductionDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-productionDate"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 120
                    }
                    ,                    
                    {
                        name: 'ExpiryDate',
                        displayName: 'Expiry Date',
                        field: 'ExpiryDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-expiryDate"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 120
                    },
                    {
                        name: 'ScrapDate',
                        displayName: 'Scrap Date',
                        field: 'ScrapDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-scrapDate"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 120
                    }
                    
                ]
            };
           
        }
  

        angular.module("app").controller("salesOrderSkuUpdateCtrl", controllers.sales.salesOrderSkuUpdateCtrl);
    }
}